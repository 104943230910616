import React from "react";

import AsciiTable from "./AsciiTable";

const Home = () => {
  return (
    <>
      <AsciiTable />
    </>
  );
};

export default Home;
